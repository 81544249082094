import React from 'react';

import classes from './contact-data.module.css';

export default function ContactData() {
  return (
    <span className={classes.main}>
      <span>0211/97634927</span>
      <span className="mx-2">|</span>
      <span>hello@harvey.one</span>
    </span>
  );
}
