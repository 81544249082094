import React, { useState, useEffect } from 'react';

import classes from './navbar.module.css';
import LanguageSwitch from '../LanguageSwitch';
import CustomDrawer from '../CustomDrawer';
import MobileNav from '../MobileNav';
import NavMenu from '../NavMenu';
import logoWhite from './logo-white.png';
import logoDark from './logo-dark.svg';
import menuWhite from './menu-white.svg';
import menuDark from './menu-dark.svg';

export default function Navbar({ transitionAllowed = false }) {
  const [isNavbarWhite, setNavbarWhite] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // navbar-sticky theme determiner
  function setNavbarTheme() {
    if (transitionAllowed) {
      const { offsetTop } = document.getElementById('headerTitle');
      setNavbarWhite(window.pageYOffset > Math.max(offsetTop - 50, 1));
    } else {
      setNavbarWhite(true);
    }
  }
  // navbar theme on scroll
  useEffect(() => {
    setNavbarTheme();
    document.addEventListener('scroll', setNavbarTheme);
    return () => {
      document.removeEventListener('scroll', setNavbarTheme);
    };
  });

  function openDrawer() {
    setDrawerOpen(true);
  }
  function closeDrawer() {
    setDrawerOpen(false);
  }

  const themeClassName = isNavbarWhite ? classes.navbarWhite : classes.navbarTransparent;
  const logo = isNavbarWhite ? logoDark : logoWhite;
  const menu = isNavbarWhite ? menuDark : menuWhite;

  return (
    <nav className={classes.navbar + ' ' + themeClassName}>
      <a className={classes.logoLink} href="/#/">
        <img className={classes.logo} src={logo} alt="Schmiede.one" />
      </a>
      <div className="d-none d-lg-flex align-items-center">
        <div className="mr-5">
          <NavMenu />
        </div>
        <LanguageSwitch />
      </div>
      <button className={classes.menuButton} onClick={openDrawer}>
        <img src={menu} alt="Menu" />
      </button>
      <CustomDrawer open={drawerOpen} onClose={closeDrawer}>
        <MobileNav onClose={closeDrawer} />
      </CustomDrawer>
    </nav>
  );
}
