import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './header.module.css';
import down from './chevron-down.svg';
import videoMp4 from './header.mp4';
import videoWebm from './header.webm';
import poster from './header.jpg';
import CustomDialog from '../../../components/CustomDialog';

export default function HomepageHeader() {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  function openDialog() {
    setDialogOpen(true);
  }
  function closeDialog() {
    setDialogOpen(false);
  }
  function handleMoreClick() {
    const introBlock = document.getElementById('harvest');  // TODO: parse ID dynamically
    const { offsetTop } = introBlock;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <header className={classes.header} data-scrolleffect="true">
      <video className={classes.video} autoPlay muted loop poster={poster}>
        <source src={videoMp4} type="video/mp4" />
        <source src={videoWebm} type="video/webm" />
      </video>
      <div className={classes.overlay}>
        <div className={classes.titleBlock}>
          <Fade bottom delay={800}>
            <h1 id="headerTitle" className={classes.title}>{t('Selbstfahrender Roder')}</h1>
            <p className={classes.subtitle}>{t('Selbstfahrender Roder für anspruchsvolle Hackfrüchte')}</p>
          </Fade>
        </div>
        <div>
          <div className={classes.detailsWrapper}>
            <Fade left cascade delay={900}>
            <div className={classes.detailView}>
              <div className={classes.item}>
                <p className={classes.valueWrapper}>
                  <span className={classes.value}>75</span>
                  &nbsp;
                  <span className={classes.subvalue}>cm</span>
                </p>
                <p className={classes.label}>{t('Reihenweite')}</p>
              </div>
              <div className={classes.item}>
                <p className={classes.valueWrapper}>
                  <span className={classes.value}>40</span>
                  &nbsp;
                  <span className={classes.subvalue}>mm</span>
                </p>
                <p className={classes.label}>{t('Teilung Siebbänder')}</p>
              </div>
              <div className={classes.item}>
                <p className={classes.valueWrapper}>
                  <span className={classes.value}>1.000</span>
                  &nbsp;
                  <span className={classes.subvalue}>kg</span>
                </p>
                <p className={classes.label}>{t('Ladekapazität')}</p>
              </div>
            </div>
            </Fade>
            <div className="mb-2 mb-lg-0 ml-lg-5">
              <Fade right delay={1000}>
                <button className="btn btn-outline-light" onClick={openDialog}>
                  {t('Frage senden')}
                </button>
              </Fade>
            </div>
          </div>
          <a href="#harvest" className={classes.moreLink} onClick={handleMoreClick}>
            <img
              className={classes.down}
              src={down}
              alt={t('Mehr Infos')}
            />
          </a>
        </div>
      </div>
      <CustomDialog open={dialogOpen} onClose={closeDialog} />
    </header>
  );
}
