import React from 'react';

export default function ImprintDe() {
  return (
    <>
      <h1 className="mb-4">Impressum</h1>

      <div><b>Schmiede.ONE GmbH &amp; Co.KG</b></div>
      <div>Hunteburger Str. 32</div>
      <div>49401 Damme</div>
      <div>Telefon +49 5491 666-0</div>
      <div>Telefax +49 5491 666-2298</div>
      <div>E-Mail: hello@schmiede.one</div>
      <div>Internet: www.schmiede.one</div>

      <div className="mb-4"></div>

      <div><b>Umsatzsteuer-Identifikationsnummer:</b></div>
      <div>
        Wird zugeteilt Rechtsform: Gesellschaft mit beschränkter Haftung, Sitz Damme, Amtsgericht Oldenburg HR A 205333
      </div>
      <div>
        Persönliche Gesellschafterin: Schmiede.ONE Beteiligungs GmbH, Sitz Damme, Amtsgericht Oldenburg HR B 211812
      </div>

      <div className="mb-4"></div>

      <div><b>Geschäftsführer:</b></div>
      <div>Herr Christoph Grimme</div>
      <div>Herr Alexander Langer</div>
    </>
  );
}
