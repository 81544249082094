import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './assistance.module.css';
import tracking from './tracking.svg';
import heightControl from './height-control.svg';
import videoMp4 from './formation.mp4';
import videoWebm from './formation.webm';
import poster from './formation.jpg';

export default function HomepageAssistance() {
  const { t } = useTranslation();
  return (
    <article id="assistance" className={classes.container} data-scrolleffect="true">
      <section className="container-fluid py-5 px-xl-5">
        <section className="row px-lg-3 px-xl-0">
          <section className="col-12 mb-5 col-xl-4 mb-xl-0">
            <header className={classes.header}>
              <Fade>
                <div>{t('Überblick')}</div>
                <h1 className="h2">{t('Assistenzsysteme')}</h1>
              </Fade>
            </header>
          </section>
          <section className="col-12 mb-5 col-md-6 mb-md-0 col-xl-4">
            <div className={classes.card}>
              <Fade bottom>
                <div className={classes.iconWrapper}>
                  <img className={classes.icon} src={tracking} alt={t('Spurfindung')} />
                </div>
                <p className="mb-0">
                  <span className="mr-3">•</span>
                  <b>{t('Spurfindung')}</b>: {t('assistance.Spurfindung.descr')}
                </p>
              </Fade>
            </div>
          </section>
          <section className="col-12 col-md-6 col-xl-4">
            <div className={classes.card}>
              <Fade bottom>
                <div className={classes.iconWrapper}>
                  <img className={classes.icon} src={heightControl} alt={t('Höhensteuerung')} />
                </div>
                <p className="mb-0">
                  <span className="mr-3">•</span>
                  <b>{t('Höhensteuerung')}</b>: {t('assistance.Höhensteuerung.descr')}
                </p>
              </Fade>
            </div>
          </section>
        </section>
      </section>
      <section className={classes.formation}>
        <img className={classes.image} src={poster} alt="Formation" />
        <video className={classes.video} autoPlay muted loop poster={poster}>
          <source src={videoMp4} type="video/mp4" />
          <source src={videoWebm} type="video/webm" />
        </video>
      </section>
    </article>
  );
}
