import Cookies from 'js-cookie';

export default function getCurrentLanguage() {
  const languageCookie = Cookies.get('language');
  if (languageCookie) {
    return languageCookie;
  }

  return 'de';  // TODO: parse browser language
}
