import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';

export default function Layout({ children }) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="sticky-top">
        <Navbar />
      </div>
      { children }
      <div className="flex-grow-1"></div>
      <Footer />
    </div>
  );
}
