import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './benefits.module.css';
import videoWebm from './benefits.webm';
import videoMp4 from './benefits.mp4';
import poster from './poster.jpg';
import soil from './soilcompression.png';
import part from './partchangeover.png';
import assistance from './assistancesystems.png';

export default function Benefits() {
  const { t } = useTranslation();
  return (
    <article id="benefits" className={classes.main} data-scrolleffect="true">
      <section className={classes.imageWrapper}>
        <video className={classes.video} autoPlay muted loop poster={poster}>
          <source src={videoMp4} type="video/mp4"></source>
          <source src={videoWebm} type="video/webm"></source>
        </video>
      </section>
      <section className={classes.textSection}>
        <header className="mb-5">
          <Fade>
            <div>{t('Ihre Vorteile')}</div>
            <h1 className={`h2 ${classes.heading}`} dangerouslySetInnerHTML={{
              __html: t('Sonderkulturen roden')
            }} />
          </Fade>
        </header>
          <div className={classes.cardGrid}>
            <div className={classes.card}>
              <Fade right>
                <div className={classes.iconWrapper}>
                  <img className={classes.icon} src={soil} alt="Soil Compression" />
                </div>
                <p className="mb-0">
                  {t('Minimale Bodenverdichtung')}
                </p>
              </Fade>
            </div>
            <div className={classes.card}>
              <Fade left>
                <div className={classes.iconWrapper}>
                  <img className={classes.icon} src={part} alt="Part Changeover" />
                </div>
                <p className="mb-0">
                  {t('Siebband und Dammtrommel')}
                </p>
              </Fade>
            </div>
            <div className={classes.card}>
              <Fade right>
                <div className={classes.iconWrapper}>
                  <img className={classes.icon} src={assistance} alt="Assistance Systems" />
                </div>
                <p className="mb-0">
                  {t('Konzentrieren Sie auf die Frucht')}
                </p>
              </Fade>
            </div>
          </div>
      </section>
    </article>
  );
}
