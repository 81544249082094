import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import ContactForm from '../ContactForm';
import ContactData from '../ContactData';
import close from './close.svg';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
  },
  paper: {
    borderRadius: 0,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  header: {
    textAlign: 'right',
    padding: '1rem',
  },
  closeButton: {
    border: 'none',
    background: 'none',
  },
  content: {
    padding: '0 1.5rem 3rem',
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem 3rem 3rem',
    },
  },
  footer: {
    textAlign: 'center',
    padding: '.5rem 0',
    backgroundColor: 'black',
    color: 'white',
  },
}));

export default function CustomDialog({ open, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      scroll="body"
      classes={{
        container: classes.container,
        paper: classes.paper,
      }}
    >
      <header className={classes.header}>
        <button className={classes.closeButton} onClick={onClose}>
          <img alt={t('Schließen')} src={close} />
        </button>
      </header>
      <section className={classes.content}>
        <ContactForm onClose={onClose} />
      </section>
      <footer className={classes.footer}>
        <ContactData />
      </footer>
    </Dialog>
  );
}
