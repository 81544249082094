import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './contact-form.module.css';
import TextField from '../CustomTextField';

function sendFormData(formData) {
  const url = 'https://c9x1hg89w9.execute-api.us-east-1.amazonaws.com/dev/email/send';
  fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData),
  })
    .then(() => {
      console.log('Contact form request successfully sent.');
    });
}

export default function ContactForm({ onClose = () => {} }) {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      content: e.target.message.value,
    };
    sendFormData(formData);
  }

  return (
    <article className={classes.main}>
      <section className="position-relative">
        <section style={{
          visibility: submitted ? 'hidden' : 'visible',
        }}>
          <h1
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: t('Kontaktiere uns')
            }}
          />
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <TextField name="name" label={t('Name')} fullWidth required />
            </div>
            <div className="mb-3">
              <TextField type="email" name="email" label={t('E-mail')} fullWidth required />
            </div>
            <div className="mb-5">
              <TextField
                name="message"
                label={t('Nachricht')}
                multiline
                rows={5}
                fullWidth
                required
              />
            </div>
            <div className="text-right">
              <button type="submit" className="btn btn-outline-dark">{t('Senden')}</button>
            </div>
          </form>
        </section>
        <section className="position-absolute" style={{
          top: 0,
          display: submitted ? 'block' : 'none',
        }}>
          <h1
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: t('Vielen Dank')
            }}
          />
          <p className="mb-4">
            {t('Wir werden uns in Kürze mit Ihnen in Verbindung setzen.')}
          </p>
          <div>
            <button className="btn btn-outline-dark" onClick={onClose}>
              {t('Schließen')}
            </button>
          </div>
        </section>
      </section>
    </article>
  );
}
