import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './layout.module.css';
import classes2 from './rental.module.css';
import videoWebm from './rental.webm';
import videoMp4 from './rental.mp4';
import poster from './rental.jpg';
import CustomDialog from '../../../components/CustomDialog';
import getCurrentLanguage from '../../../i18n/get-current-language';

export default function Rental() {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const language = getCurrentLanguage();

  function getLocaleMonth(monthIndex) {
    return (new Date(2020, monthIndex))
      .toLocaleString(language, { month: 'long' });
  }
  const jan = getLocaleMonth(0);
  const apr = getLocaleMonth(3);
  const may = getLocaleMonth(4);
  const oct = getLocaleMonth(9);
  const nov = getLocaleMonth(10);
  const dec = getLocaleMonth(11);
  // ... add lacking months following this pattern

  function openDialog() {
    setDialogOpen(true);
  }
  function closeDialog() {
    setDialogOpen(false);
  }

  return (
    <article id="rental" className={classes.main} data-scrolleffect="true">
      <section className={classes.imageWrapper}>
        <video className={classes.video} autoPlay muted loop poster={poster}>
          <source src={videoMp4} type="video/mp4"></source>
          <source src={videoWebm} type="video/webm"></source>
        </video>
      </section>
      <section className={classes.textSection}>
        <header className="mb-5">
          <Fade>
            <div>{t('Überblick')}</div>
            <h1 className="h2"><b>{t('Mietoptionen')}</b></h1>
          </Fade>
        </header>
        <Fade delay={100}>
          <p className="mb-5">
            {t('rental.descr')}
          </p>
        </Fade>
        <div className="mb-5 px-sm-4 px-md-5 px-lg-2">
          <Fade delay={200}>
            <table className="w-100">
              <tbody>
                <tr className={classes2.tr}>
                  <th className={classes2.th} scope="row">Mechanic</th>
                  <td className={classes2.td}>
                    <span>{t('rental.auf')}</span>
                    &nbsp;
                    <span className={classes2.request}>{t('Anfrage')}</span>
                  </td>
                </tr>
                <tr className={classes2.tr}>
                  <th className={classes2.th} scope="row">Autonomy</th>
                  <td className={classes2.td}>
                    <span>{t('rental.auf')}</span>
                    &nbsp;
                    <span className={classes2.request}>{t('Anfrage')}</span>
                  </td>
                </tr>
                <tr className={classes2.tr}>
                  <th className={classes2.th} scope="row">Multicrop</th>
                  <td className={classes2.td}>
                    <span>{t('rental.auf')}</span>
                    &nbsp;
                    <span className={classes2.request}>{t('Anfrage')}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Fade>
        </div>
        <div className="text-center text-lg-right">
          <Fade delay={300}>
            <button className="btn btn-outline-dark" onClick={openDialog}>
              {t('Nachricht schreiben')}
            </button>
          </Fade>
        </div>
      </section>
      <CustomDialog open={dialogOpen} onClose={closeDialog} />
    </article>
  );
}
