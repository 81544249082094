import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './arguments.module.css';
import helpers from './helpers.png';
import procImg from './process.png';
import soil from './soil.svg';

export default function Arguments() {
  const { t } = useTranslation();
  const cardHeadingClassName = 'h3 ' + classes.cardHeading;
  return (
    <article className={classes.container} data-scrolleffect="true">
      <section className={classes.main}>
        <header className="mb-4 px-3 mb-lg-5">
          <Fade>
            <div>{t('Gute Argumente')}</div>
            <h1 className="h2">{t('Wirtschaftlich und nachhaltig roden')}</h1>
          </Fade>
        </header>
        <section className="text-center">
          <Fade left cascade>
            <section className={classes.grid}>
              <article className={classes.card}>
                <header className="text-center mb-4">
                  <div className={classes.cardSubheading}>{t('Mechanisierung und Ergonomie')}</div>
                  <h2 className={cardHeadingClassName}>{t('Erntehelfer einsparen')}</h2>
                </header>
                <p className={classes.cardImageWrapper}>
                  <img className={classes.cardImage} alt="Helpers" src={helpers} />
                </p>
                <p className={classes.cardParagraph}>
                  {t('arguments.descr1')}
                </p>
              </article>
              <article className={classes.card}>
                <header className="text-center mb-4">
                  <div className={classes.cardSubheading}>{t('Sonderkulturen wirtschaftlich roden')}</div>
                  <h2 className={cardHeadingClassName}>{t('Prozess vereinfachen')}</h2>
                </header>
                <p className={classes.cardImageWrapper}>
                  <img className={classes.cardImage} alt="Process" src={procImg} />
                </p>
                <p className={classes.cardParagraph}>
                  {t('arguments.descr2')}
                </p>
              </article>
              <article className={classes.card}>
                <header className="text-center mb-4">
                  <div className={classes.cardSubheading}>{t('Raupenlaufwerk und Gesamtgewicht')}</div>
                  <h2 className={cardHeadingClassName}>{t('Bodenqualität steigern')}</h2>
                </header>
                <p className={classes.cardImageWrapper}>
                  <img className={classes.cardImage} alt="Soil Quality" src={soil} />
                </p>
                <p className={classes.cardParagraph}>
                  {t('arguments.descr3')}
                </p>
              </article>
            </section>
          </Fade>
        </section>
        <div></div>
      </section>
    </article>
  );
}
