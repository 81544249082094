import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'black',
    color: 'white',
  },
});

export default function CustomDrawer({ children, open, onClose }) {
  const classes = useStyles();
  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
    >
      { children }
    </MuiDrawer>
  );
}
