import React from 'react';
import { useTranslation } from 'react-i18next';

import './nav.css';

export default function NavMenu({ onLinkClick = () => {} }) {
  const { t } = useTranslation();
  return (
    <ul className="nav flex-column flex-lg-row" onClick={onLinkClick}>
      <li className="nav-item">
        <a className="nav-link" href="/#harvest">{t('Früchte')}</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/#features">{t('Maschine')}</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/#assistance">{t('Assistenzsysteme')}</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/#rental">{t('Jetzt Mieten')}</a>
      </li>
    </ul>
  );
}
