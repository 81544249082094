import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

import classes from './features.module.css';
import FeaturesModel from './Model';
import FeaturesDetail from './Detail';
import features from './data';

export default function HomepageFeatures() {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);

  function handleActiveChange(i) {
    if (i === active) {
      setActive(0);
    } else {
      setActive(i);
    }
  }
  const handleSelect = (i, e) => {
    setActive(i);
  };

  function renderCarouselItems() {
    return features.map((x, i) => (
      <Carousel.Item key={i}>
        <div style={{ paddingBottom: '5rem' }}>
          <FeaturesDetail {...features[i]} />
        </div>
      </Carousel.Item>
    ));
  }

  return (
    <article id="features" className={classes.main} data-scrolleffect="true">
      <section className={classes.head}>
        <section className="container pt-5 pb-2">
          <section className="row">
            <section className="col-12 col-md-7 col-lg-6">
              <header className={classes.header}>
                <Fade>
                  <div>{t('Details entdecken')}</div>
                  <h1 className="h2" dangerouslySetInnerHTML={{
                    __html: t('41 Ps warten auf Ihre Hackfrüchte')
                  }} />
                </Fade>
              </header>
              <Fade bottom>
                <p className={classes.headText}>
                  {t('features.descr')}
                </p>
              </Fade>
              <div className={classes.line}></div>
            </section>
            <section className="col-12 col-md-5 col-lg-6 text-md-right">
              <div className={classes.machineLinkWrapper}>
                <a className="btn btn-outline-dark" href="/#/maschine">
                  <span dangerouslySetInnerHTML={{
                    __html: t('Maschine in 360 entdecken'),
                  }} />
                </a>
              </div>
            </section>
          </section>
        </section>
      </section>
      <section className={classes.machine}>
        <FeaturesModel active={active} onActiveChange={handleActiveChange} />
      </section>
      <section className={classes.details}>
        <section className="container">
          <div className={classes.triangle}></div>
          <section className="row">
            <section className="col-12 pt-3 pb-4 d-lg-none">
              <Carousel
                controls={false}
                fade={true}
                interval={null}
                activeIndex={active}
                onSelect={handleSelect}
              >
                {renderCarouselItems()}
              </Carousel>
            </section>
            <section className="d-none d-lg-block col-lg-4 pt-lg-3 pb-lg-5">
              <FeaturesDetail {...features[active]} />
            </section>
          </section>
        </section>
      </section>
    </article>
  );
}
