import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Homepage from './Homepage';
import Layout from './components/Layout';
import Imprint from './pages/Imprint';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound';
import Machine from './pages/Machine';

export default function App() {
  const { t } = useTranslation();
  const [routeHash, setRouteHash] = useState(window.location.hash);

  window.onhashchange = (e) => {
    setRouteHash(e.target.location.hash);
  };

  function renderRoute() {
    const route = routeHash.split('#/')[1];
    if (!route) {
      return <Homepage />;
    }
    switch (route) {
      case 'impressum': {
        return <Layout><Imprint /></Layout>;
      }
      case 'datenschutz': {
        return <Layout><PrivacyPolicy /></Layout>;
      }
      case 'maschine': {
        return <Machine />;
      }
      default:
        return <Layout><NotFound /></Layout>;
    }
  }

  return (
    <div>
      <Helmet>
        <title>{t('Selbstfahrender Roder')}</title>
        <meta
          name="description"
          content={t('Hackfrucht Roder')}
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-103011220-1"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-103011220-1');
        `}</script>
      </Helmet>
      <main>
        {renderRoute()}
      </main>
    </div>
  );
}
