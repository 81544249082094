import img0 from './roder.png';
import img1 from './maximale-produktschonung.png';
import img2 from './motor-und-hydraulik.png';
import img3 from './verstellbare-raupenlaufwerke.png';
import img4 from './aufnahme.png';

// NOTE: all the `title` and `description` values are I18N keys
// that you can find and review under `src/i18n/translations` directory

const features = [
  {
    title: 'Allgemeine Information',
    description: 'features.feature0.descr',
    image: img0,
  },
  {
    title: 'Maximale Produktschonung',
    description: 'features.feature1.descr',
    image: img1,
  },
  {
    title: 'Motor und Hydraulik',
    description: 'features.feature2.descr',
    image: img2,
  },
  {
    title: 'Verstellbare Raupenlaufwerke',
    description: 'features.feature3.descr',
    image: img3,
  },
  {
    title: 'Grimme Aufnahme',
    description: 'features.feature4.descr',
    image: img4,
  },
];

export default features;
