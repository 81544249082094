import React from 'react';

import classes from './line.module.css';
import { ReactComponent as Line1 } from './line-1.svg';
import { ReactComponent as Line2 } from './line-2.svg';
import { ReactComponent as Line3 } from './line-3.svg';
import { ReactComponent as Line4 } from './line-4.svg';

export default function FeaturesLines({ active = 0 }) {
  return (
    <div className={classes.main}>
      {active === 1 && <Line1 className={classes.line} stroke="white" fill="white" />}
      {active === 2 && <Line2 className={classes.line} stroke="white" fill="white" />}
      {active === 3 && <Line3 className={classes.line} stroke="white" fill="white" />}
      {active === 4 && <Line4 className={classes.line} stroke="white" fill="white" />}
    </div>
  );
}
