import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './footer.module.css';
import ContactData from '../ContactData';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className={classes.footer}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center mb-3 col-lg-4 text-lg-left mb-lg-0">
            <a className={classes.brandLink} href="/#/">
              <strong>Schmiede.one</strong>
            </a>
          </div>
          <div className="col-12 text-center mb-3 col-lg-4 mb-lg-0">
            <a href="/#/impressum">{t('Impressum')}</a>
            &nbsp;
            <span>|</span>
            &nbsp;
            <a href="/#/datenschutz">{t('Datenschutz')}</a>
          </div>
          <div className="col-12 text-center col-lg-4 text-lg-right">
            <ContactData />
          </div>
        </div>
      </div>
    </footer>
  );
}
