import React from 'react';

import getCurrentLanguage from '../../i18n/get-current-language';
import ImprintEn from './imprint-en';
import ImprintDe from './imprint-de';

export default function Imprint() {
  function renderBody() {
    const language = getCurrentLanguage();
    if (language === 'en') {
      return <ImprintEn />;
    }
    return <ImprintDe />; // German version by default
  }

  return (
    <article className="container py-5" style={{ lineHeight: 1.5 }}>
      {renderBody()}
    </article>
  );
}
