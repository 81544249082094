import React from 'react';
import Cookies from 'js-cookie';

import classes from './language-switch.module.css';
import getCurrentLanguage from '../../i18n/get-current-language';

export default function LanguageSwitch() {
  const language = getCurrentLanguage();

  function activeIf(cond) {
    return cond ? 'font-weight-bold' : 'font-weight-normal';
  }
  function setLanguage(lang, e) {
    e.preventDefault();
    Cookies.set('language', lang, { expires: 30 });
    window.location.reload();
  }

  return (
    <div className={classes.main}>
      <a className={classes.link} href="/" onClick={e => setLanguage('de', e)}>
        <span className={activeIf(language === 'de')}>de</span>
      </a>
      &nbsp;
      <span className={classes.separator}>|</span>
      &nbsp;
      <a className={classes.link} href="/" onClick={e => setLanguage('en', e)}>
        <span className={activeIf(language === 'en')}>en</span>
      </a>
    </div>
  );
}
