import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import classes from './machine.module.css';
import Navbar from '../../components/Navbar';
import model from './model.fbx';
import close from './close.svg';
import grass from './bg/pz.jpg';
//import grass from './concrete.jpg';
import fullscreen from './fullscreen.svg';
import cameraImage from './camera.svg';

import px from './bg/px.jpg';
import py from './bg/py.jpg';
import pz from './bg/py.jpg';
import nx from './bg/nx.jpg';
import ny from './bg/pz.jpg';
import nz from './bg/ny.jpg';

// mui styles
const useStyles = makeStyles({
  iconButton: {
    backgroundColor: 'rgba(0, 0, 0, .1) !important',
    outline: 'none !important',
  },
});

export default function Machine() {
  const { t } = useTranslation();
  const muiClasses = useStyles();
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const canvas = document.getElementById('machine');
    const width = canvas.clientWidth - 1;
    const height = canvas.clientHeight;

    // scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xcce0ff );
    const cubeTexture = new THREE.CubeTextureLoader().load([px, nx, py, ny, pz, nz]);
    scene.background = cubeTexture;
    //scene.fog = new THREE.Fog( 0xcce0ff, 8, 20 );
    //scene.fog = new THREE.Fog( 0x6ccbfe, 8, 20 );
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 3;

    // renderer
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(width, height);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    canvas.appendChild(renderer.domElement);

    // 3D model
    let object;
    const loader = new FBXLoader();
    loader.load(model, obj => {
      object = obj;
      const scale = 0.0006;
      object.scale.set(scale, scale, scale);
      object.rotateX(-Math.PI / 2);
      object.rotateZ(-Math.PI / 4);
      object.position.y = 0.2;
      object.castShadow = true;
      scene.add(object);
      scene.traverse(i => {if (i.isMesh) i.material.shininess = 100});  // setting model shininess
      setLoading(false);
    }, null, err => console.error(err));

    // lights
    scene.add( new THREE.AmbientLight( 0x888888 ) );  // ambient light
    const light = new THREE.DirectionalLight( 0xdfebff, 1 );  // primary light source
    light.position.set( -180, 150, 300 );
    light.position.multiplyScalar( 1.3 );
    scene.add( light );
    const light2 = new THREE.DirectionalLight( 0xdfebff, 0.4 ); // secondary light source
    light2.position.set( -50, 50, -100 );
    light2.castShadow = true;
    scene.add( light2 );
    const light3 = new THREE.DirectionalLight( 0xdfebff, 0.4 ); // secondary light source
    light3.position.set( 300, 50, -150 );
    light3.castShadow = true;
    scene.add( light3 );

    // mouse controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI / 2;
    controls.maxDistance = 3;
    controls.enablePan = false;
    controls.update();

    // ground
    const textureLoader = new THREE.TextureLoader();
    const groundTexture = textureLoader.load(grass);
    groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
    groundTexture.repeat.set( 200, 200 );
    groundTexture.anisotropy = 16;
    groundTexture.encoding = THREE.sRGBEncoding;
    const groundMaterial = new THREE.MeshLambertMaterial( { map: groundTexture } );
    let mesh = new THREE.Mesh( new THREE.PlaneBufferGeometry( 500, 500 ), groundMaterial );
    mesh.position.y = -1;
    mesh.rotation.x = -Math.PI / 2;
    mesh.receiveShadow = true;
    scene.add( mesh );

    // resize handler
    function onWidowResize() {
      const _width = canvas.clientWidth - 1;
      const _height = canvas.clientHeight;
      camera.aspect = _width / _height;
      camera.updateProjectionMatrix();
      renderer.setSize(_width, _height);
    }
    window.addEventListener('resize', onWidowResize);

    // animate
    let autoRotate = true;
    function animate() {
      requestAnimationFrame(animate);
      controls.autoRotate = autoRotate;
      controls.update();
      renderer.render(scene, camera);
    }
    animate();

    // toggling auto-rotate
    function handleCameraButtonClick(e) {
      autoRotate = !autoRotate;
    }
    const cameraButton = document.getElementById('cameraButton');
    cameraButton.addEventListener('click', handleCameraButtonClick);

    // memory cleanup
    return () => {
      window.removeEventListener('resize', onWidowResize);
      cameraButton.removeEventListener('click', handleCameraButtonClick);
      object && scene.remove(object);
      renderer.renderLists.dispose();
      scene.dispose();
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setOverlayVisible(true);
      }, 400);
    }
  }, [loading]);

  function handleFullScreenClick() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const canvas = document.getElementById('machine');
      if (canvas.requestFullscreen) {
        canvas.requestFullscreen()
      } else if (canvas.webkitRequestFullscreen) {
        canvas.webkitRequestFullscreen(); // safari
      }
    }
  }

  function dismissOverlay() {
    setOverlayVisible(false);
  }

  function renderOverlay() {
    if (overlayVisible) {
      return (
        <div className={classes.hintOverlay}>
          <p className={classes.hintText}>
            {t('Klicken und ziehen Sie um das Modell zu drehen')}
          </p>
          <p className={classes.hintText}>
            {t('Zoom mit Mausrad')}
          </p>
          <button className="btn btn-outline-light" onClick={dismissOverlay}>OK</button>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="d-flex flex-column vh-100">
      <Navbar />
      <div className={classes.main}>
        <div className={classes.loadingStateBlock}>
          <p className="h5 text-muted">
            {t('Szenerie wird geladen')}
          </p>
        </div>
        <section
          id="machine"
          className={classes.canvasContainer}
          style={{
            visibility: loading ? 'hidden' : 'visible',
          }}
        >
          <div className={classes.topRightPanel}>
            <IconButton
              disableRipple
              disableFocusRipple
              classes={{ root: muiClasses.iconButton }}
              onClick={handleFullScreenClick}
            >
              <img alt={t('Fullscreen')} src={fullscreen} />
            </IconButton>
            <span className="mr-2"></span>
            <IconButton
              disableRipple
              disableFocusRipple
              classes={{ root: muiClasses.iconButton }}
              id="cameraButton"
            >
              <img alt={t('Camera')} src={cameraImage} />
            </IconButton>
            <span className="mr-2"></span>
            <a href="/#/">
              <IconButton
                disableRipple
                disableFocusRipple
                classes={{ root: muiClasses.iconButton }}
              >
                <img alt={t('Schließen')} src={close} />
              </IconButton>
            </a>
          </div>
        </section>
        {renderOverlay()}
      </div>
    </div>
  );
}
