import de from './translations/de.json';
import en from './translations/en.json';

const i18nResources = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
};

export default i18nResources;
