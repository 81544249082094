import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './factory.module.css';
import videoWebm from './factory.webm';
import videoMp4 from './factory.mp4';
import poster from './poster.jpg';
import experience from './experience.png';
import part from './partchangeover.png';
import assistance from './assistancesystems.png';

export default function Factory() {
  const { t } = useTranslation();
  return (
    <article id="factory" className={classes.main} data-scrolleffect="true">
      <section className={classes.textSection}>
        <header className="mb-5">
          <Fade>
            <div>{t('Erfahrene Partner')}</div>
            <h1 className="h2" dangerouslySetInnerHTML={{
              __html: t('Hergestellt von ASA-LIFT')
            }} />
          </Fade>
        </header>
        <div className={classes.cardGrid}>
          <div className={classes.card}>
            <Fade left>
              <div className={classes.iconWrapper}>
                <img className={classes.icon} src={experience} alt="Experience" />
              </div>
              <p className="mb-0">
                {t('160 Jahre Erfahrung')}
              </p>
            </Fade>
          </div>
          <div className={classes.card}>
            <Fade right>
              <div className={classes.iconWrapper}>
                <img className={classes.icon} src={part} alt="Part Changeover" />
              </div>
              <p className="mb-0">
                {t('Bewährte Baugruppen')}
              </p>
            </Fade>
          </div>
          <div className={classes.card}>
            <Fade left>
              <div className={classes.iconWrapper}>
                <img className={classes.icon} src={assistance} alt="Assistance Systems" />
              </div>
              <p className="mb-0">
                {t('Ersatzteile weltweit verfügbar')}
              </p>
            </Fade>
          </div>
        </div>
      </section>
      <section className={classes.imageWrapper}>
        <video className={classes.video} autoPlay muted loop poster={poster}>
          <source src={videoMp4} type="video/mp4"></source>
          <source src={videoWebm} type="video/webm"></source>
        </video>
      </section>
    </article>
  );
}
