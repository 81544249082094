import React from 'react';

import classes from './point.module.css';

export default function FeaturesPoint({
  left = 0,
  bottom = 0,
  active = false,
  onClick = () => {},
}) {
  return (
    <div
      className={classes.container}
      style={{
        left: `${left}%`,
        bottom: `${bottom}%`,
      }}
    >
      <div className="position-relative w-100 h-100">
        <button
          className={classes.button}
          onClick={onClick}
        >
          <span className={classes.icon}></span>
        </button>
      </div>
    </div>
  );
}
