import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './button.module.css';

export default function CropChoiceButton({ item, active, onCropChange = () => {} }) {
  const { t } = useTranslation();
  const buttonClassName = `${classes.button} ${active ? classes.active : null}`;
  return (
    <div className={classes.buttonWrapper}>
      <button className={buttonClassName} onClick={e => onCropChange(item.id)}>
        <img className={classes.icon} src={item.icon} alt={t(item.cropName)} />
        <span
          className={classes.cropTitle}
          dangerouslySetInnerHTML={{ __html: t(item.cropName) }}
        />
      </button>
    </div>
  );
}
