import React from 'react';

import classes from './model.module.css';
import model from './machine.png';
import Point from '../Point';
import Lines from '../Lines';

export default function FeaturesModel({ active, onActiveChange }) {
  return (
    <div className={classes.main}>
      <div className={classes.imageWrapper}>
        <img className={classes.image} alt="Model" src={model} />
        <Point
          left={83}
          bottom={59}
          active={active === 1}
          onClick={() => onActiveChange(1)}
        />
        <Point
          left={72}
          bottom={43}
          active={active === 2}
          onClick={() => onActiveChange(2)}
        />
        <Point
          left={60}
          bottom={21}
          active={active === 3}
          onClick={() => onActiveChange(3)}
        />
        <Point
          left={13}
          bottom={21}
          active={active === 4}
          onClick={() => onActiveChange(4)}
        />
        <Lines active={active} />
      </div>
    </div>
  );
}
