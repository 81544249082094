import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import mdDe from './privacy-policy.de.md';
import mdEn from './privacy-policy.en.md';
import getCurrentLanguage from '../../i18n/get-current-language';

export default function PrivacyPolicy() {
  const [source, setSource] = useState('');

  async function fetchMd(file) {
    const res = await fetch(file);
    const text = await res.text();
    setSource(text);
  }
  useEffect(() => {
    const language = getCurrentLanguage();
    const mdFile = language === 'en' ? mdEn : mdDe; // German by default
    fetchMd(mdFile)
  }, []);

  return (
    <article className="container py-5">
      <ReactMarkdown source={source} />
    </article>
  );
}
