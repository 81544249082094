import React from 'react';

import classes from './mobile-nav.module.css';
import NavMenu from '../NavMenu';
import LanguageSwitch from '../LanguageSwitch';
import logo from './logo-white.png';
import close from './close.svg';
import ContactData from '../ContactData';

export default function MobileNav({ onClose }) {
  return (
    <nav className={classes.container}>
      <header className="d-flex justify-content-between mb-5">
        <a href="/" onClick={onClose}>
          <img className={classes.logo} alt="Schmiede.one" src={logo} />
        </a>
        <button className={classes.closeButton} onClick={onClose}>
          <img alt="Close" src={close} />
        </button>
      </header>
      <section className={classes.main}>
        <div className="mb-5">
          <LanguageSwitch />
        </div>
        <NavMenu onLinkClick={onClose} />
      </section>
      <footer className={classes.footer}>
        <ContactData />
      </footer>
    </nav>
  );
}
