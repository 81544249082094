import React, { useCallback, useEffect, useRef } from 'react';

import Navbar from '../components/Navbar';
import HomepageHeader from './components/Header';
import HomepageFeatures from './components/Features';
import HomepageAssistance from './components/Assistance';
import Rental from './components/Rental';
import CropChoice from './components/CropChoice';
import Benefits from './components/Benefits';
import Factory from './components/Factory';
import Arguments from './components/Arguments';
import Footer from '../components/Footer';

export default function Homepage() {
  const ref = useRef(null);

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      try {
        const element = document.querySelector(hash);
        if (element) {
          // this timeout is needed so all homepage elements are initialized
          // and the scroll stops right at the target element (not somewhat above)
          setTimeout(() => {
            window.scrollTo({
              top: element.offsetTop,
              left: 0,
              behavior: 'smooth',
            });
          }, 200);
        }
      } catch (err) {}
    }
  }, []);

  // scroll effect
  function scrollToBlock(e) {
    const isDown = e.deltaY > 0;
    const scrollElementsList = document.querySelectorAll('[data-scrolleffect="true"]');
    const scrollElements = Array.from(scrollElementsList);
    const navbarHeight = getComputedStyle(document.body).getPropertyValue('--navbar-height');
    const shift = +navbarHeight.split('px')[0] / 2;
    const targetCoord = window.scrollY + shift;
    const elementsOnTop = scrollElements.filter(x => {
      const top = x.offsetTop;
      const bottom = top + x.offsetHeight;
      return top <= targetCoord && bottom > targetCoord;
    });
    let targetElement;
    if (!isDown) {
      targetElement = elementsOnTop[0];
    } else {
      const currentElement = elementsOnTop[elementsOnTop.length - 1];
      const currentIndex = scrollElements.indexOf(currentElement);
      targetElement = scrollElements[currentIndex + 1];
    }
    if (targetElement) {
      e.preventDefault();
      window.scrollTo({
        top: targetElement.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
  //let timeout;  // for storing timeout ID, LEGACY
  let scrollAllowed = true;
  function handleWheel(e) {
    if (window.innerWidth >= 992) {
      if (scrollAllowed) {
        scrollToBlock(e);
        scrollAllowed = false;
        setTimeout(() => {
          scrollAllowed = true;
        }, 1500);
      } else {
        e.preventDefault();
      }
      /*timeout && clearTimeout(timeout); // LEGACY
      timeout = setTimeout(() => {
        scrollToBlock(e);
      }, 500);*/
    }
  }
  const memoisedHandleWheel = useCallback(handleWheel);
  useEffect(() => {
    const refCurrent = ref.current;
    refCurrent.addEventListener('wheel', memoisedHandleWheel);
    return () => {
      refCurrent.removeEventListener('wheel', memoisedHandleWheel);
    };
  }, [memoisedHandleWheel]);

  return (
    <article ref={ref} data-testid="homepage">
      <div className="fixed-top">
        <Navbar transitionAllowed />
      </div>
      <HomepageHeader />
      <CropChoice/>
      <Arguments />
      <Benefits />
      <HomepageFeatures />
      <HomepageAssistance />
      <Factory />
      <Rental />
      <Footer />
    </article>
  );
}
