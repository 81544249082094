import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  label: {
    color: 'black',
    fontFamily: '"Barlow", sans-serif',
  },
  focused: {
    color: 'black !important',
  },
  asterisk: {
    display: 'none',
  },
  underline: {
    '&::after': {
      borderBottomColor: 'black',
    },
  },
});

export default function CustomTextField(props) {
  const classes = useStyles();
  return (
    <TextField
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          asterisk: classes.asterisk,
        }
      }}
      InputProps={{
        classes: {
          underline: classes.underline,
        }
      }}
      {...props}
    />
  );
}
