import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import classes from './cropchoice.module.css';
import crops from './cropList';
import CropChoiceButton from './Button';

function CropVideo({ item, active }) {
  const videoRef = useRef(null);
  useEffect(() => {
    if (active) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [active]);
  const videoClassName = `${classes.video} ${active ? classes.active : null}`;
  return (
    <video ref={videoRef} className={videoClassName} muted loop poster={item.poster}>
      <source src={item.video} type="video/mp4" />
      <source src={item.videoWebm} type="video/webm" />
    </video>
  );
}

export default function CropChoice() {
  const { t } = useTranslation();
  const [activeCrop, setActiveCrop] = useState(0);

  function handleCropChange(index) {
    index === activeCrop ? setActiveCrop(0) : setActiveCrop(index);
  }

  function renderButtons() {
    return crops.slice(1).map((item, index) => {
      const delay = Math.round(Math.random() * 500);
      return (
        <Fade key={index} bottom delay={delay}>
          <CropChoiceButton
            item={item}
            active={activeCrop === item.id}
            onCropChange={handleCropChange}
          />
        </Fade>
      );
    });
  }

  function getHeading() {
    const item = crops[activeCrop];
    return item.cropName;
  }

  function renderVideos() {
    return crops.map((item, i) => {
      const active = i === activeCrop;
      return <CropVideo key={i} item={item} active={active} />
    });
  }

  return (
    <article id="harvest" className={classes.main} data-scrolleffect="true">
      <section className={classes.textSection}>
        <header className={classes.header}>
          <Fade>
            <div>{t('Was möchten Sie roden?')}</div>
            <h1 className="h2" dangerouslySetInnerHTML={{ __html: t(getHeading()) }} />
          </Fade>
        </header>
        <section className={classes.buttonGrid}>
          {renderButtons()}
        </section>
      </section>
      <section className={classes.videoSection}>
        {renderVideos()}
      </section>
    </article>
  );
}
