import potato from './images/potato.png';
import garlic from './images/garlic-2.png';
import sweetpotato from './images/sweet-potato.png';
import onion from './images/onion.png';
import topinambur from './images/topinambur-01.png';
import manjok from './images/maniok-01.png';
import carrot from './images/carrot.png';
import beet from './images/beet.png';
import radies from './images/radies.png';
import yacon from './images/yacon.png';

import defaultVideo from './videos/default.mp4';
import defaultWebm from './videos/default.webm';
import defaultPoster from './videos/default.jpg';

import potatoesVideo from './videos/potatoes.mp4';
import potatoesWebm from './videos/potatoes.webm';
import potatoesPoster from './videos/potatoes.jpg';

import sweetPotatoesVideo from './videos/sweet-potatoes.mp4';
import sweetPotatoesWebm from './videos/sweet-potatoes.webm';
import sweetPotatoesPoster from './videos/sweet-potatoes.jpg';

import onionsVideo from './videos/onions.mp4';
import onionsWebm from './videos/onions.webm';
import onionsPoster from './videos/onions.jpg';


const cropList = [
  {
    id: 0,
    cropName: 'Hackfrüchte',
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
  {
    id: 1,
    cropName: 'Saatkartoffeln',
    icon: potato,
    video: potatoesVideo,
    videoWebm: potatoesWebm,
    poster: potatoesPoster,
  },
  {
    id: 2,
    cropName: 'Süßkartoffeln',
    icon: sweetpotato,
    video: sweetPotatoesVideo,
    videoWebm: sweetPotatoesWebm,
    poster: sweetPotatoesPoster,
  },
  {
    id: 3,
    cropName: 'Knoblauch',
    icon: garlic,
    video: onionsVideo,
    videoWebm: onionsWebm,
    poster: onionsPoster,
  },
  {
    id: 4,
    cropName: 'Zwiebeln',
    icon: onion,
    video: onionsVideo,
    videoWebm: onionsWebm,
    poster: onionsPoster,
  },
  {
    id: 5,
    cropName: 'Topinambur',
    icon: topinambur,
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
  {
    id: 6,
    cropName: 'Maniok',
    icon: manjok,
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
  {
    id: 7,
    cropName: 'Karotten',
    icon: carrot,
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
  {
    id: 8,
    cropName: 'Rüben',
    icon: beet,
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
  {
    id: 9,
    cropName: 'Radieschen',
    icon: radies,
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
  {
    id: 10,
    cropName: 'Yacon',
    icon: yacon,
    video: defaultVideo,
    videoWebm: defaultWebm,
    poster: defaultPoster,
  },
];

export default cropList;
