import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './detail.module.css';

export default function FeaturesDetail({ title, description, image }) {
  const { t } = useTranslation();
  return (
    <div className={classes.main}>
      <div>
        <h2 className="h4 mb-4" dangerouslySetInnerHTML={{ __html: t(title) }}></h2>
        <p className="mb-4">{t(description)}</p>
      </div>
      <div className={classes.imageWrapper}>
        <img
          className={classes.image}
          src={image}
          alt={t(title)}
        />
      </div>
    </div>
  );
}
