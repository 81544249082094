import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <article data-testid="not-found" className="container py-5">
      <h1>404 Not Found</h1>
      <p>
        <a href="/">{t('Zur Startseite')}</a>
      </p>
    </article>
  );
}
